import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react";
import React from "react";

import { $count, $length, $string } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { Nullable } from "foundation-ts/types";

import { ModalAction, ModalWindowState } from "./ModalConstants";
import { G1Button } from "../G1Button";

export const DefaultAsyncConfirmModalState:AsyncConfirmModalState = {
    message: undefined,
    onAccept: async () => {},
    onClose: async () => {}
} ;

export interface AsyncConfirmModalState extends ModalWindowState {
    message: Nullable<string>;
    onAccept: () => Promise<void>;
    confirm?:Nullable<string>;
    cancel?:Nullable<string>;
    actions?:Nullable<ModalAction[]>
}

export const AsyncConfirmModal = (props: AsyncConfirmModalState) => {
    const { onClose, title, message, onAccept, confirm, cancel, size = "lg", actions } = props;
    const realTitle = $string(title) ;
    const topPadding = realTitle.length > 0 ? "0px" : "15px" ;

    return (
        <Modal onClose={onClose} size={size} isOpen={$length(message)>0} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{$string(title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody paddingTop={topPadding}>{$string(message)}</ModalBody>
                <ModalFooter margin="auto">
                    <Stack direction='row' marginRight="2%" spacing={4} align='left'>
                        <G1Button component="global" variant="cancel" title={_trim(cancel, "Non")} type="button" minWidth={"100px"} onClick={onClose} />
                        {$count(actions) > 0 && actions!.map((action, index) => (
                            <G1Button component="global" variant="alternate" title={action!.label} type="button" minWidth={"100px"} key={`acmb${index}`} onClick={async () => action!.onSelect(action!.value)} />
                        ))}
                        <G1Button component="global" variant="normal" title={_trim(confirm, "Oui")} type="button" minWidth="100px" onClick={onAccept} />
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

function _trim(s:Nullable<string>, d:string):string {
    const t = $trim(s) ;
    return t.length > 0 ? t : d ;
}
