
// lists
export const SectionsListQueryID                = "sections" ;
export const DocumentsListQueryID               = "documents" ;
export const InvoicesListQueryID                = "invoices" ;
export const MessagesListQueryID                = "messages" ;
export const NotificationsListQueryID           = "notifications" ;
export const CorporationsListQueryID            = "corporations" ;
export const CountriesID                        = "countries" ;
export const AvailableCorporationIdentifiersID  = "corporationIdentifiers" ;
export const TemplateSessionListQueryID         = "templateSessions" ;

// object or object's part
export const ContactsQueryID            = "contacts" ;
export const SessionQueryID             = "session" ;
export const DocumentContentID          = "document" ;
export const CorporationUsersQueryID    = "corporationUsers" ;
