import React from "react";
import { Stack, StackProps } from "@chakra-ui/react";

import { $g1Color } from "../@chakra-ui/gatsby-plugin/G1Style";

interface SeaShellBoxProps extends StackProps {
  component: "global" | "form" ;
}

export const SeaShellBox: React.FC<SeaShellBoxProps> = (props) => {
  const { id, component, maxW, height, minH, flex, spacing, visibility, mt, mb } = props;
  return (
    <Stack
      id={id}
      spacing={spacing}
      align="stretch"
      flex={flex}
      minH={minH}
      display="flex"
      flexDirection="column"
      color={$g1Color(`${component}.write`)}
      bg={$g1Color(`${component}.bg`)}
      p="2"
      height={height}
      maxW={maxW}
      borderColor={$g1Color(`${component}.border`)}
      borderWidth="1px"
      width="100%"
      mx="auto"
      rounded={{ base: "0", md: "xl" }}
      visibility={visibility}
      mt={mt}
      mb={mb}
    >
      {props.children}
    </Stack>
  );
};
