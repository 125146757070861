import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react";
import React from "react";

import { $string } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { Nullable } from "foundation-ts/types";

import { G1Button } from "../G1Button";
import { ModalWindowState } from "./ModalConstants";

export const DefaultAsyncActionModalState:AsyncActionModalState = {
    element: null,
    isOpen: false,
    onAccept: async () => {},
    onClose: async () => {},
} ;


export interface AsyncActionModalState extends ModalWindowState {
    element: Nullable<JSX.Element> ;
    isOpen: boolean ;
    confirm?: Nullable<string> ;
    cancel?: Nullable<string> ;
    disabledConfirm?: boolean ;
    onAccept: () => void ;
}

export const AsyncActionModal = (props: AsyncActionModalState) => {
    const { title, message, isOpen, disabledConfirm, confirm, cancel, size = 'md', element, onClose, onAccept } = props ;
        
    return (
        <Modal onClose={onClose} size={size} isOpen={isOpen} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{$string(title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{$string(message)}</ModalBody>
                {element}
                <ModalFooter margin="auto">
                    <Stack direction='row' marginRight="2%" spacing={4} align='left'>
                        <G1Button component="global" variant="cancel" title={$trim(cancel)} type="button" minWidth="100px" onClick={onClose} />
                        <G1Button component="global" variant="normal" title={$trim(confirm)} type="button" minWidth="100px" disabled={disabledConfirm} onClick={onAccept} />
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    ) ;
} ;