import React, { ReactNode } from "react";

import { Flex } from "@chakra-ui/layout";
import { StyleProps } from "@chakra-ui/react";
interface ColumnTitleProps extends StyleProps {
  children: ReactNode;
}

export const ColumnTitle = (props: ColumnTitleProps) => {
  const { color, children, display, padding = "5% 0", marginBottom, width, visibility } = props;

  return (
    <Flex
      display={display}
      direction="row"
      width={width}
      padding={padding}
      marginBottom={marginBottom}
      color={color}
      fontWeight="500"
      visibility={visibility}
    >
      {children}
    </Flex>
  );
};
